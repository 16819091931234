// extracted by mini-css-extract-plugin
export var headerTitle = "index-module--header-title--Z9aPV";
export var cover = "index-module--cover--EhSgb";
export var mediaCoverContainer = "index-module--media-cover-container--VcLpb";
export var mediaCover = "index-module--media-cover--1OVvB";
export var homeHeader = "index-module--home-header--+KtV3";
export var headerText = "index-module--headerText--LYMq6";
export var section = "index-module--section--gS4hO";
export var profilePictureContainer = "index-module--profile-picture-container--qH3tf";
export var profilePicture = "index-module--profile-picture--N4S8l";
export var profilePictureImg = "index-module--profile-picture-img--ENYGc";
export var profilePictureText = "index-module--profile-picture-text--kl0B8";
export var socialMediaBaner = "index-module--social-media-baner--gPStq";
export var paddedText = "index-module--paddedText--Mm1Zm";
export var white = "index-module--white--PkO99";
export var center = "index-module--center--WMh1L";
export var loadingSpinner = "index-module--loading-spinner--nxTrh";
export var imageZoomContainer = "index-module--image-zoom-container--CE6Rg";
export var imageGallery = "index-module--imageGallery--u+awe";