import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/site/layout";
import { Card3D, Card3DContainer, Card3DContent, Card3DHeader } from "../components/site/Card/Card3D";
import SEO from "../components/site/seo";
import { Container, Col, Row } from "react-bootstrap";
import SliderGlide from "../components/site/slider-glide";
import * as stylesInsta from "../styles/instagram.module.scss";
import * as styles from "../styles/index.module.scss";
import { FaHeart, FaComment, FaHorse, FaGamepad } from "react-icons/fa";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import Button from "../components/site/Button/button";
import { isBrowser } from "../services/helper";
import moment from "moment";
import "moment/locale/fr";
 
export default class IndexPage extends React.Component {
  timeline = null;
  trigger = null;
  text1 = null;
  test = [];
  video = null;

  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      isLoading: false,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  componentDidMount() {
    
    if (isBrowser()) {
      gsap.registerPlugin(ScrollTrigger);
      gsap.registerPlugin(ScrollToPlugin);
      //gsap.core.globals("ScrollTrigger", ScrollTrigger)
      this.updateWindowDimensions();
      window.addEventListener("resize", this.updateWindowDimensions);
    }

    const endAnimation = { opacity: 1, duration: 0.6, ease: "Circ.easeIn" };
    gsap.fromTo(
      this.card1,
      { opacity: 0, x: -500, y: -200 },
      { ...endAnimation, scrollTrigger: this.card1, x: 0, y: 0, duration: 0.5 }
    );
    gsap.fromTo(this.card2, { opacity: 0, rotateY: 180 }, { ...endAnimation, scrollTrigger: this.card2, rotateY: 0 });
    gsap.fromTo(
      this.card3,
      { opacity: 0, x: 500, y: -200 },
      { ...endAnimation, scrollTrigger: this.card3, x: 0, y: 0, duration: 0.5 }
    );
    gsap.fromTo(
      this.profilePicture,
      { opacity: 0, rotateY: 180 },
      {
        scrollTrigger: this.profilePicture,
        opacity: 1,
        duration: 1,
        rotateY: 0,
      }
    );

    this.test.forEach((element) => {
      gsap.fromTo(element, { opacity: 0 }, { opacity: 1, scrollTrigger: element, duration: 1, ease: "Circ.easeIn" });
    });
  }

  componentWillUnmount() {
    if (isBrowser()) {
      window.removeEventListener("resize", this.updateWindowDimensions);
    }
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  /* */

  render() {
    console.log(styles);
    const laetitiaYear = moment().diff(moment().set("day", 15).set("month", 11).set("year", 1995), "years");
    return (
      <StaticQuery
        query={graphql`
          query {
            homeImg: file(sourceInstanceName: { eq: "images" }, name: { eq: "centre equestre vue relief" }) {
              childImageSharp {
                gatsbyImageData
              }
            }
            imageLaetitia: file(sourceInstanceName: { eq: "images" }, name: { eq: "laetitia" }) {
              childImageSharp {
                gatsbyImageData
              }
            }
            socialMedias: allFile(filter: { name: { regex: "/social-media/" } }) {
              edges {
                node {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            allInstagramContent {
              edges {
                node {
                  id
                  caption
                  permalink
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        `}
        render={(data) => (
          <Layout currentPage="/" messageReservation={true} headerAbsolute={true}>
            <SEO title="Accueil" />
            {/*this.state.isLoading && (
              <div className={styles.loadingSpinner}>
                <div>
                  <RingLoader css={"display:block"} size={250} color={"#bd7e04"} loading={true} />
                  <h2>Chargement du site... Merci de patienter quelques secondes</h2>
                </div>
              </div>
            )*/}
            <section>
              <div className={styles.cover}>
                <div className={styles.mediaCoverContainer}>
                  <GatsbyImage
                    className={styles.mediaCover}
                    image={data.homeImg.childImageSharp.gatsbyImageData}
                    alt="Les écuries du Chalet - centre equestre annecy epagny haute savoie"
                  />
                  <video
                    className={styles.mediaCover}
                    alt={"Les écuries du Chalet - centre equestre annecy epagny haute savoie"}
                    loop
                    autoPlay
                    muted
                    src="https://res.cloudinary.com/dwom9zrye/video/upload/v1598949702/video_centre_pylp6n.mp4"
                    type="video/mp4"
                    playsInline
                    preload="auto"
                    onTimeUpdate={() => {
                      this.setState({ isLoading: false });
                    }}
                  ></video>
                </div>

                <div className={styles.homeHeader}>
                  <Button
                    style={{ width: "220px" }}
                    href="#"
                    onClick={() =>
                      isBrowser() &&
                      gsap.to(window, {
                        duration: 1,
                        scrollTo: { y: "#first-section", offsetY: 80 },
                      })
                    }
                  >
                    Voir plus
                  </Button>
                  <div style={{ display: "none" }} className={styles.headerText}>
                    <h2 className={styles.headerTitle}>
                      <span style={{ fontSize: "smaller" }}>Les Écuries de la Lisière deviennent :</span> Les Écuries du
                      Cha'let !!
                    </h2>
                    <p>
                      Laetitia Challut (moi-même) est heureuse de vous annoncer le changement de propriétaire, pour le 1
                      <sup>er</sup> septembre 2020 !
                    </p>
                    <Button
                      style={{ width: "220px" }}
                      href="#"
                      onClick={() =>
                        isBrowser() &&
                        gsap.to(window, {
                          duration: 1,
                          scrollTo: { y: "#first-section", offsetY: 80 },
                        })
                      }
                    >
                      Voir plus
                    </Button>
                  </div>
                </div>
              </div>
            </section>

            <section id="first-section" className={styles.section} ref={(div) => (this.containerPresta = div)}>
              <h1
                className="mt test"
                ref={(div) => {
                  this.test.push(div);
                }}
              >
                Les prestations
              </h1>
              <Card3DContainer id="perspectiveCards">
                <Card3D
                  ref={(div) => {
                    this.card1 = div;
                  }}
                >
                  <Card3DHeader>
                    <FaGamepad />
                    <h2>Cours collectifs</h2>
                  </Card3DHeader>
                  <Card3DContent>
                    <p>
                      Je vous accueille en cours collectifs que vous ayez 3 ou 99 ans, toute l'année{" "}
                      <span className="small">(hors vacances scolaires)</span>.
                    </p>
                    <p>Du débutant au confirmé, je serai ravie de vous enseigner l'art de monter à cheval.</p>
                  </Card3DContent>
                </Card3D>
                <Card3D
                  ref={(div) => {
                    this.card2 = div;
                  }}
                >
                  <Card3DHeader>
                    <FaGamepad />
                    <h2>Stages</h2>
                  </Card3DHeader>
                  <Card3DContent>
                    <p>
                      Pendant les vacances, quoi de mieux qu'une semaine pour découvrir ou enrichir son équitation ?
                    </p>
                    <p>De 9h à 12h ou de 14h à 17h pendant toutes les vacances scolaires.</p>
                  </Card3DContent>
                </Card3D>
                <Card3D
                  ref={(div) => {
                    this.card3 = div;
                  }}
                >
                  <Card3DHeader>
                    <FaGamepad />
                    <h2>Pensions</h2>
                  </Card3DHeader>
                  <Card3DContent>
                    <p>Vous avez un cheval et vous souhaitez le meilleur pour lui ?</p>
                    <p>
                      Les Écuries du Chalet mettent à votre disposition un box, plus de 3 hectares de pré, un manège de
                      40x20m, une carrière aux pieds de la Mandallaz.
                    </p>
                  </Card3DContent>
                </Card3D>
              </Card3DContainer>
            </section>

            <Container>
              <section className={`mt ${styles.section}`}>
                <h1
                  className="test"
                  ref={(div) => {
                    this.test.push(div);
                  }}
                >
                  La monitrice
                </h1>

                <div
                  className={styles.profilePictureContainer}
                  ref={(div) => {
                    this.profilePicture = div;
                  }}
                >
                  <Container className={`${styles.profilePictureText}`} style={{ textAlign: "center" }}>
                    <div
                      className={styles.paddedText}
                      ref={(div) => {
                        this.test.push(div);
                      }}
                    >
                      <h2>Qui suis-je ?</h2>
                      <p>Laetitia Challut, {laetitiaYear} ans, diplômée d'État depuis 2017.</p>
                    </div>
                  </Container>

                  <div className={styles.profilePicture}>
                    <GatsbyImage
                      className={styles.profilePictureImg}
                      image={data.imageLaetitia.childImageSharp.gatsbyImageData}
                      alt="Laetitia Challut monitrice centre équestre Annecy Epagny - Les écuries du Chalet"
                    />
                  </div>
                </div>
              </section>
            </Container>

            <section className={`${styles.section} ${stylesInsta.section}`}>
              <Container>
                <Row className={"mt " + styles.center}>
                  <h1
                    ref={(div) => {
                      this.test.push(div);
                    }}
                  >
                    Me suivre sur les réseaux
                  </h1>
                </Row>
                <Row className={styles.center}>
                  <Col
                    className="mt-xs"
                    md="6"
                    ref={(div) => {
                      this.test.push(div);
                    }}
                  >
                    <a href="https://www.facebook.com/lesecuriesduchalet" target="_blank" rel="noreferrer">
                      <GatsbyImage
                        image={data.socialMedias.edges[0].node.childImageSharp.gatsbyImageData}
                        className={styles.socialMediaBaner}
                      />
                    </a>
                  </Col>
                  <Col
                    className="mt-xs"
                    md="6"
                    ref={(div) => {
                      this.test.push(div);
                    }}
                  >
                    <a href="https://www.instagram.com/les_ecuries_du_chalet" target="_blank" rel="noreferrer">
                    <GatsbyImage
                        image={data.socialMedias.edges[1].node.childImageSharp.gatsbyImageData}
                        className={styles.socialMediaBaner}
                      />
                    </a>
                  </Col>
                </Row>
              </Container>

              {data.allInstagramContent && (
                <SliderGlide className="instagram-glide mt-s" style={{ overflow: "hidden" }}>
                  {data.allInstagramContent.edges.map((instaPost) => {
                    instaPost = instaPost.node;
                    return (
                      instaPost.localFile && (
                        <div
                          className={stylesInsta.post}
                          key={instaPost.id}
                          ref={(div) => {
                            this.test.push(div);
                          }}
                        >
                          <a
                            alt="{{caption}}"
                            href={instaPost.permalink}
                            target="_blank"
                            rel="noreferrer"
                            title={instaPost.caption}
                            style={{ textDecoration: "none" }}
                          >
                            <GatsbyImage
                              className={stylesInsta.img}
                              image={instaPost.localFile.childImageSharp.gatsbyImageData}
                              alt={instaPost.caption}
                            />
                            {/*
                          <div className={stylesInsta.content}>
                            <div className={stylesInsta.likes}>
                              <FaHeart className={stylesInsta.logo} /> {instaPost.likes ? instaPost.likes : 0} likes
                            </div>
                            <div className={stylesInsta.likes}>
                              <FaComment className={stylesInsta.logo} /> {instaPost.comments ? instaPost.comments : 0}{" "}
                              commentaires
                            </div>
                          </div>*/}
                          </a>
                        </div>
                      )
                    );
                  })}
                </SliderGlide>
              )}
            </section>

            <span style={{ height: "1px", overflow: "hidden", display: "flex" }}>
              Page d'accueil du{" "}
              <h1>
                centre équestre du chalet - écuries du Cha'let - écuries du chalet à Annecy Epagny en Haute Savoie.
              </h1>
              A quelques minutes de Genève en Suisse. Entre compétition et loisir, tout le monde y trouve sa place.
              Balades à cheval, poney, manège et grande carrière de sable 20x40 m, des installations de qualité. Cours
              de poney et de chevaux. Faites découvrir cette passion à votre enfant. Cheval pour enfant (chevaux pour
              enfants) Passage de tous <b>galops</b> du 1 au 7. Ce <h2>centre équestre - poney club</h2> situé
              idéalement à Epagny et a seulement quelques minutes <b>D'Annecy</b>, possède toute l'infrastructure pour
              la réussite de ses <b>cavaliers</b>. Nous mettons tout en place que votre experience soit la meilleure
              possible ! Plusieurs hectares de terrains sont disponibles pour des pensions de chevaux, ainsi que des
              boxs copeaux !
            </span>
          </Layout>
        )}
      />
    );
  }
}
